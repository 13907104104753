@JsModule("firebase/app")
@JsNonModule
external class FirebaseModule {
    companion object {
        fun initializeApp(options: FirebaseOptions): FirebaseApp
    }
}

interface FirebaseOptions {
    var apiKey: String
        get() = this.asDynamic()["apiKey"]
        set(v) {
            this.asDynamic()["apiKey"] = v
        }
    var authDomain: String
        get() = this.asDynamic()["authDomain"]
        set(v) {
            this.asDynamic()["authDomain"] = v
        }
    var projectId: String
        get() = this.asDynamic()["projectId"]
        set(v) {
            this.asDynamic()["projectId"] = v
        }
    var storageBucket: String
        get() = this.asDynamic()["storageBucket"]
        set(v) {
            this.asDynamic()["storageBucket"] = v
        }
    var messagingSenderId: String
        get() = this.asDynamic()["messagingSenderId"]
        set(v) {
            this.asDynamic()["messagingSenderId"] = v
        }
    var appId: String
        get() = this.asDynamic()["appId"]
        set(v) {
            this.asDynamic()["appId"] = v
        }
    var measurementId: String
        get() = this.asDynamic()["measurementId"]
        set(v) {
            this.asDynamic()["measurementId"] = v
        }
}


class Impl2 : FirebaseOptions {
    init {
        apiKey = "AIzaSyArO6c0y4IlK9ZefmKTN_BAuzASaEbLnQc"
        authDomain = "telegram-874a4.firebaseapp.com"
        projectId = "telegram-874a4"
        storageBucket = "telegram-874a4.appspot.com"
        messagingSenderId = "741109193872"
        appId = "1:741109193872:web:7bea34520ebf5fa0a08c15"
        measurementId = "G-TDBYH125HQ"
    }
}


external interface FirebaseApp {
    val options: FirebaseOptions
    fun firestore(): Firestore
}

external interface Firestore {
    fun batch(): Any
    fun collection(path: String): Any
    fun doc(path: String): Any
}

