package com.sample

import FirebaseAnalyticsModule
import FirebaseModule
import Impl2
import androidx.compose.runtime.remember
import com.sample.components.Layout
import com.sample.components.MainContentLayout
import com.sample.content.ApiSample
import com.sample.content.CodeSamples
import com.sample.content.ComposeWebLibraries
import com.sample.content.GetStarted
import com.sample.content.Header
import com.sample.content.Intro
import com.sample.content.JoinUs
import com.sample.content.PageFooter
import com.sample.style.AppStylesheet
import org.jetbrains.compose.web.css.Style
import org.jetbrains.compose.web.renderComposable

fun main() {
    renderComposable(rootElementId = "root") {
        val app = remember { FirebaseModule.initializeApp(Impl2()) }
        val analytics = remember { FirebaseAnalyticsModule.getAnalytics(app) }

        console.log("analytics:")
        console.log(analytics)

        Style(AppStylesheet)

        Layout {
            Header()
            MainContentLayout {
                ApiSample()
                Intro()
                ComposeWebLibraries()
                GetStarted()
                CodeSamples()
                JoinUs()
            }
            PageFooter()
        }
    }
}