import io.ktor.client.*
import io.ktor.client.request.*
import io.ktor.client.features.json.JsonFeature
import io.ktor.client.features.json.serializer.KotlinxSerializer

//val endpoint = window.location.origin // only needed until https://github.com/ktorio/ktor/issues/1695 is resolved
val endpoint = "https://jsonplaceholder.typicode.com" // only needed until https://github.com/ktorio/ktor/issues/1695 is resolved

val jsonClient = HttpClient {
    install(JsonFeature) { serializer = KotlinxSerializer() }
}

suspend fun getTodoList(): List<TodoListItem> {
    return jsonClient.get(endpoint + TodoListItem.path)
}

//suspend fun addShoppingListItem(shoppingListItem: ShoppingListItem) {
//    jsonClient.post<Unit>(endpoint + ShoppingListItem.path) {
//        contentType(ContentType.Application.Json)
//        body = shoppingListItem
//    }
//}
//
//suspend fun deleteShoppingListItem(shoppingListItem: ShoppingListItem) {
//    jsonClient.delete<Unit>(endpoint + ShoppingListItem.path + "/${shoppingListItem.id}")
//}