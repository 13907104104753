import kotlinx.serialization.Serializable

@Serializable
data class TodoListItem(
    val id: Int,
    val userId: Int,
    val title: String,
    val completed: Boolean,
) {
    companion object {
        const val path = "/todos"
    }
}