package com.sample.content

import TodoListItem
import androidx.compose.runtime.Composable
import androidx.compose.runtime.mutableStateListOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.rememberCoroutineScope
import com.sample.components.ContainerInSection
import com.sample.style.WtCols
import com.sample.style.WtOffsets
import com.sample.style.WtTexts
import getTodoList
import kotlinx.coroutines.launch
import org.jetbrains.compose.web.dom.Div
import org.jetbrains.compose.web.dom.Img
import org.jetbrains.compose.web.dom.P
import org.jetbrains.compose.web.dom.Text

@Composable
fun ApiSample() {

    val todos = remember { mutableStateListOf<TodoListItem>() }
    //val favourites by mutableStateListOf<Track>()
    val scope = rememberCoroutineScope()
    scope.launch {
        val items = getTodoList()
        todos.addAll(items.take(3))
    }

    ContainerInSection {

        Text("Items:")
        todos.forEach {
            TodoBlock(it.title)
        }

    }
}


@Composable
private fun TodoBlock(description: String) {
    Div(attrs = {
        classes(WtCols.wtCol3, WtCols.wtColMd6, WtCols.wtColSm12, WtOffsets.wtTopOffset48)
    }) {
        Img(src = "compose_bullet.svg")
        P(attrs = {
            classes(WtTexts.wtText1, WtTexts.wtText1HardnessHard, WtOffsets.wtTopOffset12)
        }) {
            Text(description)
        }
    }
}
